// import logo from './logo.svg';
import './App.css';
import SimpleForm from './components/SimpleForm';
// import Greet from './components/Greet'
// import Welcome from './components/Welcome';
// import Hello from './components/Hello';
// import Message from './components/Message';
// import Counter from './components/Counter';
// import FunctionClick from './components/FunctionClick';
// import ClassClick from './components/ClassClick';
// import EventBind from './components/EventBind';
// import ParentComponent from './components/ParentComponent';
// import UserGreeting from './components/UserGreeting';
// import NameList from './components/NameList';
import {Sample,List,Landing,Refer,History,Transactions, App as Sple} from '@lji/bial-webapp/dist/bial-webapp';
// import {App} from 'bail-webapp/dist/bail-webapp';
// import 'bail-webapp/dist/index'

function App() {
  return (
    <div className="App">
      <SimpleForm></SimpleForm>
      {/* <Landing></Landing> */}
      {/* <Sple></Sple> */}
      {/* <App></App> */}
      {/* <List></List> */}
      {/* <Landing></Landing> */}
      {/* // <Refer></Refer> */}
      {/* <History></History> */}
      {/* <Transactions></Transactions> */}
      {/* <UserGreeting></UserGreeting> */}
      {/* <ParentComponent></ParentComponent> */}
      {/* <EventBind></EventBind> */}
      {/* <ClassClick></ClassClick> */}
      {/* <FunctionClick></FunctionClick> */}
      {/* <Counter></Counter> */}
      {/* <Message></Message> */}
      {/* <Greet name="Piyush">
        <p>This is children props</p>
      </Greet> */}
      {/* <Greet name="Arvind">
        <button>Action</button>
      </Greet> */}
      {/* <Greet name="Kanahaiya"></Greet> */}
      {/* <Welcome name="Piyush" heroName="Batman"></Welcome> */}
      {/* <Welcome name="Arvind" heroName="Superman"></Welcome> */}
      {/* <Hello></Hello> */}
    </div>
  );
}

export default App;
