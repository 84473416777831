(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("@lji/bial-webapp", ["React", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["@lji/bial-webapp"] = factory(require("react"), require("react-dom"));
	else
		root["@lji/bial-webapp"] = factory(root["React"], root["ReactDOM"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_dom__) => {
return 