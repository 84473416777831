const getConfig = () => {
  let key = localStorage.getItem('x_api_key');
  let jwtToken = localStorage.getItem('jwtToken');
  const headers = {
    'x-api-key': key,
    'Content-Type': 'application/json',
    'Authorization': jwtToken
  };
  return headers;
};
const validateConfig = () => {
  let key = localStorage.getItem('x_api_key');
  let jwtToken = localStorage.getItem('jwtToken');
  const headers = {
    'x-api-key': key,
    'Content-Type': 'application/json'
  };
  return headers;
};
const getSrcPath = () => {
  let src = localStorage.getItem('source_path');
  return src;
};
export { getConfig, getSrcPath, validateConfig };