import { createClient } from 'contentful';
let process = {
  env: {}
};
let cmsEnv = 'uat';
if (localStorage.getItem('source_path') == 'https://api.apm13.gravty.io') {
  cmsEnv = 'master';
} else {
  cmsEnv = 'uat';
}
const client = createClient({
  space: 'vel1ejdzohgb',
  accessToken: '2-PtPMHoLM_jzlWExM4LyxsrkF_7pc3RPOqr0zUjFeE',
  environment: cmsEnv
});
export default client;